// extracted by mini-css-extract-plugin
export var applyBtn = "index-module--applyBtn--5f138";
export var faq = "index-module--faq--b6b07";
export var faqContent = "index-module--faqContent--e8c3a";
export var faqItem = "index-module--faqItem--8a7db";
export var faqQuestion = "index-module--faqQuestion--ccc6f";
export var hero = "index-module--hero--8a08f";
export var heroContent = "index-module--heroContent--9a084";
export var heroImg = "index-module--heroImg--65c9b";
export var name = "index-module--name--adab4";
export var otherQuestions = "index-module--otherQuestions--a8b4e";
export var scholarship = "index-module--scholarship--050b2";
export var scholarshipContent = "index-module--scholarshipContent--a99f4";
export var scholarshipDescription = "index-module--scholarshipDescription--9c21b";
export var scholarshipDetailSection = "index-module--scholarshipDetailSection--e6bff";
export var scholarshipDetailSectionContent = "index-module--scholarshipDetailSectionContent--4d09c";
export var scholarshipDetailSectionTitle = "index-module--scholarshipDetailSectionTitle--4658a";